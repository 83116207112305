import { v4 as uuidv4 } from "uuid";

export const clothesTshirtCottonKits = [
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболки поло",
    image: ["/img/clothes/tshirts/1-1.png", "/img/clothes/tshirts/1-2.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболки поло",
    image: ["/img/clothes/tshirts/2-1.png", "/img/clothes/tshirts/2-2.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболки поло",
    image: ["/img/clothes/tshirts/3-1.png", "/img/clothes/tshirts/3-2.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболки поло",
    image: ["/img/clothes/tshirts/4-1.png", "/img/clothes/tshirts/4-2.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Шорты",
    image: ["/img/clothes/tshirts/shorts/1.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Шорты",
    image: ["/img/clothes/tshirts/shorts/2.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка х/б",
    image: ["/img/clothes/tshirts/cotton-tshirt/1-1.jpg"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка х/б",
    image: ["/img/clothes/tshirts/cotton-tshirt/2-1.jpg"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка п/э",
    image: ["/img/clothes/tshirts/po-tshirt/1.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка п/э",
    image: ["/img/clothes/tshirts/po-tshirt/2.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка п/э",
    image: ["/img/clothes/tshirts/po-tshirt/3.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка п/э",
    image: ["/img/clothes/tshirts/po-tshirt/4.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка п/э",
    image: ["/img/clothes/tshirts/po-tshirt/5.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-9",
    name: "Футболка п/э",
    image: ["/img/clothes/tshirts/po-tshirt/6.png"],
    price: 7000,
    article: "ФХБ-10",
    category: "мужская",
  },
];
