import { v4 as uuidv4 } from "uuid";

export const footballWomenKits = [
  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма",
    image: ["/img/football/complect-100.png"],
    price: 13000,
    article: "Ф-10",
    category: "женская",
  },

  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма",
    image: ["/img/football/complect-120.png"],
    price: 13000,
    article: "Ф-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма",
    image: ["/img/football/complect-130.png", "/img/football/complect-131.png"],
    price: 13000,
    article: "Ф-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма",
    image: ["/img/football/complect-10.png"],
    price: 13000,
    article: "Ф-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма с кепкой",
    image: ["/img/football/complect-150.png"],
    price: 17000,
    article: "Ф-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма",
    image: ["/img/football/complect-160.png", "/img/football/complect-161.png"],
    price: 13000,
    article: "Ф-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма",
    image: ["/img/football/complect-30.png"],
    price: 13000,
    article: "Ф-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-2",
    name: "Футбольная форма",
    image: ["/img/football/complect-180.png"],
    price: 13000,
    article: "Ф-11",
    category: "женская",
  },
];
