export const ourClients = [
  {
    _id: 1,
    image: "/img/clientslogo/1.png",
  },
  {
    _id: 2,
    image: "/img/clientslogo/2.png",
  },
  {
    _id: 3,
    image: "/img/clientslogo/3.png",
  },
  {
    _id: 4,
    image: "/img/clientslogo/4.png",
  },
  {
    _id: 5,
    image: "/img/clientslogo/5.png",
  },
  {
    _id: 7,
    image: "/img/clientslogo/7.png",
  },
  {
    _id: 8,
    image: "/img/clientslogo/8.png",
  },
  {
    _id: 9,
    image: "/img/clientslogo/9.png",
  },
  {
    _id: 10,
    image: "/img/clientslogo/10.png",
  },
  {
    _id: 11,
    image: "/img/clientslogo/11.png",
  },
  {
    _id: 12,
    image: "/img/clientslogo/12.png",
  },
  {
    _id: 14,
    image: "/img/clientslogo/14.png",
  },
  {
    _id: 15,
    image: "/img/clientslogo/15.png",
  },
  {
    _id: 16,
    image: "/img/clientslogo/16.png",
  },
  {
    _id: 17,
    image: "/img/clientslogo/17.png",
  },
  {
    _id: 18,
    image: "/img/clientslogo/18.png",
  },
  {
    _id: 19,
    image: "/img/clientslogo/19.png",
  },
  {
    _id: 20,
    image: "/img/clientslogo/20.png",
  },
  {
    _id: 21,
    image: "/img/clientslogo/21.png",
  },
  {
    _id: 22,
    image: "/img/clientslogo/22.png",
  },
  {
    _id: 23,
    image: "/img/clientslogo/23.png",
  },
  {
    _id: 24,
    image: "/img/clientslogo/24.png",
  },
  {
    _id: 26,
    image: "/img/clientslogo/26.png",
  },
  {
    _id: 25,
    image: "/img/clientslogo/25.png",
  },
  {
    _id: 27,
    image: "/img/clientslogo/27.png",
  },
];
