import React from "react";
import LinkArrow from "../LinkArrow";
import PhotoGallery from "../../common/PhotoGallery";
import { aboutUs } from "../../../api/aboutUs.api.js";

const About = () => {
  return (
    <div className="container pt-36 pb-10 md:pt-40">
      <div className="flex flex-row px-5">
        <div className="flex flex-col">
          <LinkArrow name="О компании" />
          <div className="flex justify-left py-20">
            <h1 className="lg:translate-x-72 font-bold text-2xl lg:text-6xl z-index-90">
              О компании SilaSport
            </h1>
          </div>
          <div className="flex px-5 break-all">
            Компания основана в 2010 году. Всё началось с любви к хоккейному
            спорту. Старший сын основателя компании прошел отбор в ДЮШСА No10 по
            хоккею с шайбой, команды Астана, с этого момента зародилась идея,
            производить спортивную одежду. Поскольку форму завозили и заказывали
            с других государств, были критерии, которые не всегда устраивали -
            это сроки изготовления заказа, качество, либо по цене не приемлемые.
            Поэтому было принято решение выпускать спортивную одежду своими
            силами, сформирована команда из профильных специалистов легкой
            промышленности. Через два года мы уже экипировали детские команды и
            школы в спортивную форму собственного производства. Компания
            оказывала и оказывает поддержку молодежным командам и проектам по
            различным видам спорта. В настоящее время в сфере наших интересов не
            только хоккей, но и футбол, волейбол, баскетбол, национальные виды
            спорта — кокпар, легкая атлетика, американскии футбол, регби. Всю
            продукцию мы производим сами, что позволяет контролировать качество,
            сроки и цену. Мы стремимся модернизировать и наращивать парк
            оборудования, совершенствовать технологии, выпускать новые виды
            продукции. В фирме работают специалисты высокого уровня, от
            конструктора до швей. Собственное швейное производство. Производство
            по печати на тканях. Нанесение изображений - сублимационной печать,
            термотрансферной, ДТФ печать, вышивка. Все производственные участки
            оснащены современным оборудованием ведущих зарубежных
            производителей.
          </div>
        </div>
        {/* <img
          className="w-full h-96 min-h-full px-5 mt-20"
          src="https://deep-vision.one/upload/iblock/2f5/n7sgebzbqhu91829x24754qir94wiid8.jpg"
          alt=""
        /> */}
      </div>
      <PhotoGallery list={aboutUs} />
    </div>
  );
};

export default About;
