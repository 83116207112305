import { v4 as uuidv4 } from "uuid";

function setType() {
  return "a-8";
}

export const attributesFlag = [
  // {
  //   _id: uuidv4("sila-"),
  //   type: setType(),
  //   name: "Флаг",
  //   image: ["https://www.fan.ru/f/_katalog/big/gamashipered.jpg"],
  //   price: 13000,
  //   article: "К-10",
  //   category: "мужская",
  // },
];
