export const ourWorks = [
  {
    _id: 1,
    image: "/img/our-works/1.jpg",
  },
  {
    _id: 2,
    image: "/img/our-works/2.jpg",
  },
  {
    _id: 3,
    image: "/img/our-works/3.jpg",
  },
  {
    _id: 4,
    image: "/img/our-works/4.jpg",
  },

  {
    _id: 5,
    image: "/img/our-works/5.jpg",
  },
  {
    _id: 6,
    image: "/img/our-works/6.jpg",
  },
  {
    _id: 7,
    image: "/img/our-works/7.jpg",
  },
  {
    _id: 8,
    image: "/img/our-works/8.jpg",
  },
  {
    _id: 9,
    image: "/img/our-works/9.jpg",
  },
];
