export const aboutUs = [
  {
    _id: 1,
    image: "/img/about/1.jpg",
  },
  {
    _id: 2,
    image: "/img/about/2.jpg",
  },

  {
    _id: 3,
    image: "/img/about/6.jpg",
  },
  {
    _id: 4,
    image: "/img/about/7.jpg",
  },

  {
    _id: 5,
    image: "/img/about/9.jpg",
  },
  {
    _id: 6,
    image: "/img/about/16.jpg",
  },
  {
    _id: 7,
    image: "/img/about/17.jpg",
  },
  {
    _id: 8,
    image: "/img/about/18.jpg",
  },
  {
    _id: 9,
    image: "/img/about/23.jpg",
  },
  {
    _id: 10,
    image: "/img/about/27.jpg",
  },

  {
    _id: 11,
    image: "/img/about/29.jpg",
  },
  {
    _id: 12,
    image: "/img/about/30.jpg",
  },
];
