export const works = [
  {
    _id: 2,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/2.jpg",
  },
  {
    _id: 3,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/3.jpg",
  },

  {
    _id: 5,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/5.jpg",
  },

  {
    _id: 7,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/7.jpg",
  },
  {
    _id: 9,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/9.jpg",
  },

  {
    _id: 11,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/11.jpeg",
  },

  {
    _id: 13,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/13.jpg",
  },
  {
    _id: 14,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/14.jpg",
  },
  {
    _id: 17,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/17.jpg",
  },
  {
    _id: 18,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/18.jpg",
  },
  {
    _id: 19,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/19.jpg",
  },
  {
    _id: 20,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/20.jpg",
  },

  {
    _id: 27,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/27.jpg",
  },
  {
    _id: 28,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/28.jpg",
  },
  {
    _id: 29,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/29.jpg",
  },
  {
    _id: 30,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/30.jpg",
  },

  {
    _id: 10,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/10.jpg",
  },
  {
    _id: 4,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/4.jpeg",
  },
  {
    _id: 6,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/6.jpg",
  },
  {
    _id: 12,
    name: "Разработка игровой формы для команды",
    image: "/img/gallery/12.jpg",
  },
];
