import { v4 as uuidv4 } from "uuid";

export const kiberSportKits = [
  // {
  //   _id: uuidv4("sila-"),
  //   type: "s-2",
  //   name: "Игровые футболки",
  //   image: [
  //     "https://img.prinba.ru/thumb/images/da/dafd0b7jb38_320x0.jpg",
  //     "https://hockey-mag.ru/images/articles/hokkeynaya-ekipirovka.jpg",
  //     "https://escaper.ru/assets/images/resources/121/thumb/bsk-aw020-subl-photo-rus.png",
  //   ],
  //   price: 15000,
  //   article: "Б-10",
  //   category: "мужская",
  // },
];
