import { v4 as uuidv4 } from "uuid";

export const voleyballWomenKits = [
  {
    _id: uuidv4("sila-"),
    type: "v-2",
    name: "Волейбольная форма",
    image: ["/img/volleyball/complect-40.png"],
    price: 15000,
    article: "Б-10",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "v-2",
    name: "Волейбольная форма",
    image: ["/img/volleyball/1.jpg"],
    price: 15000,
    article: "Б-10",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "v-2",
    name: "Волейбольная форма",
    image: ["/img/volleyball/complect-50.png"],
    price: 15000,
    article: "ВО-10",
    category: "женская",
  },

  {
    _id: uuidv4("sila-"),
    type: "v-1",
    name: "Волейбольная форма полуприлегающего силуэта",
    image: ["/img/volleyball/complect-30.png"],
    price: 13000,
    article: "ВО-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "v-1",
    name: "Волейбольная форма",
    image: ["/img/volleyball/complect-1.png"],
    price: 13000,
    article: "ВО-10",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "v-1",
    name: "Волейбольная футболка прямого силуэта",
    image: ["/img/volleyball/tshirt-1.png"],
    price: 8000,
    article: "ВО-11",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "v-1",
    name: "Волейбольная майка приталенного силуэта",
    image: ["/img/volleyball/tshirt-10.png"],
    price: 9000,
    article: "ВО-11",
    category: "женская",
  },
];
