export const football = {
  name: "ФУТБОЛ",
  image: "img/menu-icons/football.png",
  list: [
    {
      _id: "f-1",
      name: "Форма мужская",
      host: "football/man",
    },
    {
      _id: "f-2",
      name: "Форма женская",
      host: "football/woman",
    },
  ],
};
