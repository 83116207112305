import { v4 as uuidv4 } from "uuid";

export const clothesTshirtPoloKits = [
  {
    _id: uuidv4("sila-"),
    type: "c-10",
    name: "Футболки поло с шортами",
    image: ["/img/polo/complect-1.png"],
    price: 18000,
    article: "ФПШ-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-10",
    name: "Футболка поло с сублимационным нанесением",
    image: ["/img/polo/polo-10.png", "/img/polo/polo-11.png"],
    price: 12000,
    article: "ФПШ-10",
    category: "женская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-10",
    name: "Футболка поло с сублимационным нанесением",
    image: ["/img/polo/polo-20.png", "/img/polo/polo-21.png"],
    price: 12000,
    article: "ФПШ-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-10",
    name: "Футболка поло с сублимационным нанесением",
    image: ["/img/polo/polo-1.png"],
    price: 12000,
    article: "ФПШ-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-10",
    name: "Футболка поло с сублимационным нанесением",
    image: ["/img/polo/polo-30.png", "/img/polo/polo-31.png"],
    price: 12000,
    article: "ФПШ-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "c-10",
    name: "Футболка поло с бейсболкой",
    image: ["/img/polo/polo-40.png"],
    price: 16000,
    article: "ФПШ-11",
    category: "мужская",
  },
];
