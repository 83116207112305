import React from "react";

const Price = () => {
  return (
    <div className="container pt-36 pb-10">
      В настоящее время цены уточняйте у менеджеров
    </div>
  );
};

export default Price;
