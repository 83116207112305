import { v4 as uuidv4 } from "uuid";

export const hockeyPlayTshirtKits = [
  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: [
      "/img/hockey/tshirts/h-tshirt-7.png",
      "/img/hockey/tshirts/h-tshirt-8.png",
    ],
    price: 13000,
    article: "Х-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: [
      "/img/hockey/tshirts/h-tshirt-10.png",
      "/img/hockey/tshirts/h-tshirt-11.png",
    ],
    price: 13000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: [
      "/img/hockey/tshirts/h-tshirt-12.png",
      "/img/hockey/tshirts/h-tshirt-13.png",
      "/img/hockey/tshirts/h-tshirt-14.png",
    ],
    price: 13000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: [
      "/img/hockey/tshirts/h-tshirt-15.png",
      "/img/hockey/tshirts/h-tshirt-19.png",
    ],
    price: 15000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: [
      "/img/hockey/tshirts/h-tshirt-28.png",
      "/img/hockey/tshirts/h-tshirt-38.png",
    ],
    price: 15000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: [
      "/img/hockey/tshirts/h-tshirt-40.png",
      "/img/hockey/tshirts/h-tshirt-41.png",
      "/img/hockey/tshirts/h-tshirt-44.png",
    ],
    price: 15000,
    article: "Х-11",
    category: "мужская",
  },

  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: ["/img/hockey/tshirts/h-tshirt-49.png"],
    price: 19000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-1",
    name: "Хоккейная майка на заказ",
    image: ["/img/hockey/tshirts/h-tshirt-51.png"],
    price: 19000,
    article: "Х-11",
    category: "мужская",
  },
];
