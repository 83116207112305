import { v4 as uuidv4 } from "uuid";

export const kokparKits = [
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма для кокпара с головным убором борык",
    image: ["/img/borik/borik-1.jpg"],
    price: 20000,
    article: "Б-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма для кокпара с головным убором борык",
    image: ["/img/borik/borik-2.jpeg"],
    price: 20000,
    article: "Б-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма национальной игры кокпар",
    image: ["/img/typesport/kokpar/kokpar-0.png"],
    price: 14000,
    article: "Б-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма национальной игры кокпар",
    image: ["/img/typesport/kokpar/kokpar-1.png"],
    price: 14000,
    article: "Б-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма национальной игры кокпар",
    image: ["/img/typesport/kokpar/kokpar-2.png"],
    price: 14000,
    article: "Б-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма национальной игры кокпар",
    image: [
      "/img/typesport/kokpar/kokpar-4.png",
      "/img/typesport/kokpar/kokpar-4-2.png",
    ],
    price: 14000,
    article: "Б-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма национальной игры кокпар",
    image: ["/img/typesport/kokpar/kokpar-3.png"],
    price: 14000,
    article: "Б-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "s-1",
    name: "Форма национальной игры кокпар",
    image: ["/img/typesport/kokpar/kokpar-6.png"],
    price: 14000,
    article: "Б-11",
    category: "мужская",
  },
];
