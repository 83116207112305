export const volleyball = {
  name: "ВОЛЕЙБОЛ",
  image: "img/menu-icons/volleyball.png",
  list: [
    {
      _id: "v-1",
      name: "Форма мужская",
      host: "volleyball/man",
    },
    {
      _id: "v-2",
      name: "Форма женская",
      host: "volleyball/woman",
    },
  ],
};
