import React from "react";
import LinkArrow from "../LinkArrow";
import TableMenu from "../../common/TableMenu";
import { info } from "../../../api/menu/header/info.api";
import ContactUs from "../ContactUs";

const Order = () => {
  return (
    <div className="container pt-36 pb-10 md:pt-40">
      <LinkArrow name="Как сделать заказ" />
      <div className="flex flex-row px-5 py-10">
        <div className="hidden lg:flex">
          <TableMenu list={info.list} />
        </div>
        <div className="flex flex-col">
          <h1 className="font-medium text-3xl pb-5">Как сделать заказ</h1>
          <p>Мы изготавливаем спортивную одежду на заказ.</p> <br />
          Заказные изделия для каждой команды изготавливаются по индивидуальному
          дизайну. Такая продукция требует тщательного согласования всех
          параметров. К ней относится заказная игровая форма, хоккейные майки,
          спортивные костюмы, куртки и т.д.
          <div className="w-full">
            <div className="flex justify-center">
              <div className="w-full md:w-1/2 ">
                <ContactUs />
              </div>
            </div>
          </div>
          <div>
            Приобрести готовую или заказать пошив спортивной одежды Сила Спорт
            можно в нашем офисе в городе Астана.
          </div>
          <div>
            Также это можно сделать дистанционно на сайте SilaSport.kz в
            разделах с интересующей продукцией. Кнопка «Сделать заявку» доступна
            на каждой странице сайта.
          </div>
          <div>
            Наконец, Вы можете выслать заявку в произвольной форме на
            электронную почту{" "}
            <a
              href="mailto:SilaSport76@mail.ru"
              className="text-dark-blue hover:text-blue"
            >
              SilaSport76@mail.ru
            </a>{" "}
            или позвонить по нашим телефонам в Астане:{" "}
            <div className="text-dark-blue hover:text-blue">
              +7 (771) 417-18-88.
            </div>
          </div>{" "}
          <br />
          <div>Не забудьте указать следующую информацию:</div>
          <ul className="list-decimal list-inside">
            <li>
              Выбрать технологию производства изделия: пошив из обычной
              окрашенной ткани или сублимационную печать на всём изделии. Если
              сублимация для нанесения фамилий, номеров, надписей, а также
              логотипов и эмблем не используется - можно выбрать из доступных
              технологий нанесения эмблем, номеров, фамилий.
            </li>
            <li>
              Создать дизайн (макет) будущего изделия. Указать: цвета,
              декоративные элементы, расположение и размер номеров и эмблем.
              Макет необходимо предоставить в электронном виде. При
              необходимости вы можете обратиться за помощью к нашим дизайнерам.
            </li>
            <li>
              Материал. Выберете ткань или трикотажное полотно, из которого
              будет изготовлен заказ.
            </li>
            <li>
              Список команды. Укажите количество и размер изделий. При
              необходимости пришлите список игроков: размер / номер / фамилия.
            </li>
          </ul>{" "}
          <br />
          <p>
            Согласно полученной заявке менеджер выставит счёт на оплату и
            согласует окончательный макет изделия (при необходимости). После
            утверждения Вами макета и поступления оплаты – наше производство
            приступит к работе.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Order;
