export const delivery = [
  {
    _id: "1",
    name: "Комплект футбольной формы",
    weight: "300-400",
  },
  {
    _id: "2",
    name: "Комплект волейбольной формы",
    weight: "350-500",
  },
  {
    _id: "3",
    name: "Комплект баскетбольной формы",
    weight: "300-500",
  },
  {
    _id: "4",
    name: "Хоккейная майка",
    weight: "600-750",
  },
  {
    _id: "5",
    name: "Костюм парадный",
    weight: "600-800",
  },
  {
    _id: "6",
    name: "Костюм ветрозащитный",
    weight: "500-600",
  },
  {
    _id: "7",
    name: "Комплект трикотажный",
    weight: " 1300-1500",
  },
  {
    _id: "8",
    name: "Куртка",
    weight: "800-1200",
  },
  {
    _id: "9",
    name: "100 трикотажных шарфов",
    weight: "12000",
  },
];
