import React from "react";

const DrawingText = ({ photo }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="px-1 lg:px-20 w-full">
        <p className="font-bold pb-2">СУБЛИМАЦИЯ </p>
        <div>
          Печать, «встраивающая» фамилию (имя, псевдоним) в ткань готового
          изделия. Нанесение происходить прямым переносом дизайна надписи
          сублимационный бумаги на крой формы.
        </div>
        <ul className="list-inside list-disc">
          <li>Подходит только синтетическим тканям</li>{" "}
          <li>
            Любой дизайн номера, ограничение по цветам (только темные цвета)
          </li>{" "}
          <li>Высокая износостойкость</li>
          <li>Нанесение не чествуется на форме</li>
        </ul>
        <p className="font-bold pt-5 pb-2">ФЛЕКС</p>{" "}
        <div>
          Трансфер вырезается с помощью режущего плоттера из специальной
          полимерной пленки, которую наносят под прессом на ткань формы. Флекса
          - гладкая пленка
        </div>
        <ul className="list-inside list-disc">
          <li>Обьемное гладкое нанисение</li>
          <li>Ощущается на форме из тонких тканей</li>
          <li>
            Только одноцветные нанесения (можно выбрать из имеющихся пленок)
          </li>
          <li>
            Цена зависит от особенности пленки, размеров номера, тиража изделии,
            стоимости дополнительных работ
          </li>
        </ul>
        <div className="font-bold pt-5 pb-2">Полноцветовой термотрансфер </div>
        <div>
          В данной технологии изображение наносится с помощью промежуточного
          носителя (трансфера), при нагревании в термопрессе.
        </div>
        <ul className="list-inside list-disc">
          <li>Обьемное гладкое нанисение</li>
          <li>Более качественные полноцветные изображения</li>
          <li>Ощущается на форме из тонких тканей</li>
          <li>Цена зависит от размеров номера,тиража изделии</li>
        </ul>
      </div>
      <img src={`/img/drawing/${photo}`} alt="" className=" w-1/2 h-full" />
    </div>
  );
};

export default DrawingText;
