import { v4 as uuidv4 } from "uuid";

export const attributesCap = [
  {
    _id: uuidv4("sila-"),
    type: "a-3",
    name: "Кепка",
    image: ["/img/attribute/cap/1.png"],
    price: 5000,
    article: "К-1",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "a-3",
    name: "Кепка",
    image: ["/img/attribute/cap/2.png"],
    price: 5000,
    article: "К-1",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "a-3",
    name: "Кепка",
    image: ["/img/attribute/cap/3.png"],
    price: 5000,
    article: "К-1",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "a-3",
    name: "Кепка",
    image: ["/img/attribute/cap/4.png"],
    price: 5000,
    article: "К-1",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "a-3",
    name: "Кепка",
    image: ["/img/attribute/cap/5.png"],
    price: 5000,
    article: "К-1",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "a-3",
    name: "Кепка",
    image: [
      "/img/attribute/cap/6.png",
      "/img/attribute/cap/8.png",
      "/img/attribute/cap/7.png",
    ],
    price: 5000,
    article: "К-1",
    category: "мужская",
  },
];
