import { v4 as uuidv4 } from "uuid";

export const hockeyTrainingTshirtKits = [
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная из готовой ткани",
    image: ["/img/typesport/trainingshirt/hoc3.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная из готовой ткани",
    image: ["/img/typesport/trainingshirt/hoc4.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная. Сублимационная печать",
    image: ["/img/typesport/trainingshirt/11.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная. Сублимационная печать",
    image: ["/img/typesport/trainingshirt/12.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная. Сублимационная печать",
    image: ["/img/typesport/trainingshirt/13.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Майка хоккейная тренировочная",
    image: [
      "/img/hockey/tshirts/h-tshirt-3.png",
      "/img/hockey/tshirts/h-tshirt-4.png",
      "/img/hockey/tshirts/h-tshirt-7.png",
    ],
    price: 11000,
    article: "Х-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Майка хоккейная тренировочная из готовой ткани",
    image: [
      "/img/hockey/tshirts/h-tshirt-8.png",
      "/img/hockey/tshirts/h-tshirt-9.png",
      "/img/hockey/tshirts/h-tshirt-10.png",
    ],
    price: 10000,
    article: "Х-10",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Майка хоккейная тренировочная",
    image: [
      "/img/hockey/tshirts/h-tshirt-13.png",
      "/img/hockey/tshirts/h-tshirt-14.png",
      "/img/hockey/tshirts/h-tshirt-15.png",
    ],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Майка хоккейная тренировочная",
    image: [
      "/img/hockey/tshirts/h-tshirt-21.png",
      "/img/hockey/tshirts/h-tshirt-25.png",
      "/img/hockey/tshirts/h-tshirt-26.png",
    ],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: ["/img/typesport/trainingshirt/1.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: [
      "/img/typesport/trainingshirt/2.png",
      "/img/typesport/trainingshirt/3.png",
    ],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: ["/img/typesport/trainingshirt/4.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: ["/img/typesport/trainingshirt/5.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: [
      "/img/typesport/trainingshirt/6.png",
      "/img/typesport/trainingshirt/7.png",
    ],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: ["/img/typesport/trainingshirt/8.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: ["/img/typesport/trainingshirt/9.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Манишки",
    image: ["/img/typesport/trainingshirt/10.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная. Сублимационная печать",
    image: ["/img/typesport/trainingshirt/15.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная. Сублимационная печать",
    image: ["/img/typesport/trainingshirt/16.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная. Сублимационная печать",
    image: ["/img/typesport/trainingshirt/17.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "h-2",
    name: "Хоккейная майка тренировочная. Сублимационная печать",
    image: ["/img/typesport/trainingshirt/18.png"],
    price: 11000,
    article: "Х-11",
    category: "мужская",
  },
];
