export const category = [
  {
    name: "футбол",
    features: [
      "Майка прямого силуэта",
      "Длинный или короткий втачной рукав",
      "Треугольные вставки из перфорированной сетки под рукавами",
      "Низ майки обработан подгибкой",
      "Трусы прямого силуэта",
      "Пояс на резинке, ширина регулируется шнуром",
      "Низ трусов обработан окантовкой",
    ],
  },
  {
    name: "брюки",
    features: [
      "Штаны –комфортного кроя",
      "ткань-высокотехнологичный материал",
      "гигроскопичный, пропускает воздух, быстросохнущий",
      "Плотность 140гр, состав 100% полиэстер с высоким показателем износостойкости",
      "состоит из 2 провязанных слоев",
      "в случае пореза изделия оно не распускается, не теряет вид и функциональность",
    ],
  },
  {
    name: "борык",
    features: ["основа из стеганой подкладки", "утеплён полоской из меха"],
  },
  {
    name: "Форма кокпар",
    features: [
      "в пошиве используется спортивный высокотехнологичный материал",
      "гигроскопичный материал, который пропускает воздух, быстросохнущий",
      "плотность 140гр, состав 100% полиэстер",
      "ткани с высоким показателем износостойкости, так как полотно с небольшими углублениями на поверхности, состоит из 2 провязанных слоев",
      "в случае пореза изделия оно не распускается, не теряет вид и функциональность",
    ],
  },
];
