import { v4 as uuidv4 } from "uuid";

export const footballManKits = [
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма",
    image: ["/img/football/complect-230.png", "/img/football/complect-231.png"],
    price: 13000,
    article: "Ф-11",
    category: "мужская",
  },
  // {
  //   _id: uuidv4("sila-"),
  //   type: "f-1",
  //   name: "Футбольная форма",
  //   image: ["/img/football/complect-1.png"],
  //   price: 13000,
  //   article: "Ф-10",
  //   category: "мужская",
  // },
  // {
  //   _id: uuidv4("sila-"),
  //   type: "f-1",
  //   name: "Футбольная форма",
  //   image: ["/img/football/complect-210.png", "/img/football/complect-211.png"],
  //   price: 13000,
  //   article: "Ф-10",
  //   category: "мужская",
  // },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма",
    image: ["/img/football/complect-10.png"],
    price: 13000,
    article: "Ф-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма",
    image: ["/img/football/complect-20.png"],
    price: 13000,
    article: "Ф-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма",
    image: ["/img/football/complect-30.png"],
    price: 13000,
    article: "Ф-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма для вратаря",
    image: ["/img/football/complect-40.png"],
    price: 20000,
    article: "Ф-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма",
    image: ["/img/football/complect-50.png"],
    price: 13000,
    article: "Ф-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма",
    image: ["/img/football/complect-60.png"],
    price: 13000,
    article: "Ф-11",
    category: "мужская",
  },
  // {
  //   _id: uuidv4("sila-"),
  //   type: "f-1",
  //   name: "Футбольная форма",
  //   image: ["/img/football/complect-70.png"],
  //   price: 13000,
  //   article: "Ф-11",
  //   category: "мужская",
  // },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футбольная форма",
    image: ["/img/football/complect-80.png"],
    price: 13000,
    article: "Ф-11",
    category: "мужская",
  },
  // {
  //   _id: uuidv4("sila-"),
  //   type: "f-1",
  //   name: "Футбольная форма",
  //   image: ["/img/football/complect-90.png"],
  //   price: 13000,
  //   article: "Ф-11",
  //   category: "мужская",
  // },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Футболка",
    image: ["/img/football/tshirt-1.png", "/img/football/tshirt-2.png"],
    price: 8000,
    article: "Ф-11",
    category: "мужская",
  },
  {
    _id: uuidv4("sila-"),
    type: "f-1",
    name: "Шорты",
    image: ["/img/football/short-1.png"],
    price: 6000,
    article: "Ф-11",
    category: "мужская",
  },
];
