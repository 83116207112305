// прописывать только текст
export const vacancies = [
  {
    _id: "1",
    name: "Графическии дизайнер",
    requirements: [
      { _id: "1", text: "знание программы Corel" },
      { _id: "2", text: "опыт работы в данном направлении" },
      { _id: "3", text: "опыт работы с широкоформатными принтарами" },
    ],
    duties: [
      { _id: "1", text: "обеспечение своевременной печати в цех" },
      { _id: "2", text: "взаимодействие с производством" },
      { _id: "3", text: "работа над дизайнами" },
    ],
  },
  {
    _id: "2",
    name: "Швея",
    requirements: [
      { _id: "1", text: "опыт работы: от 2 лет и более" },
      { _id: "2", text: "возраст: от 25 лет." },
      {
        _id: "3",
        text: "Вас ждёт постоянный объём работы, комфортные условия труда, стабильный заработок, дружный коллектив",
      },
      {
        _id: "4",
        text: "Рассматриваем только тех кто ищет работу на длительный срок",
      },
    ],
    duties: [
      {
        _id: "1",
        text: "выполнение подготовительных работ перед пошивом изделий из различных материалов",
      },
      {
        _id: "2",
        text: "непосредственно пошив (с помощью машин или вручную)",
      },
      {
        _id: "3",
        text: "контроль за работой используемых машин, их обслуживание и устранение мелких неполадок (сложными должны заниматься специалисты-ремонтники)",
      },
      {
        _id: "4",
        text: "чистка готовых изделий от производственного мусора и т. п. (меловых линий для раскройки, обрезков ткани или ниток и пр.)",
      },
      {
        _id: "5",
        text: "поддержание порядка на рабочем месте",
      },
    ],
  },
];
