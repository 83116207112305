export const serviceProducts = [
  {
    name: "command",
    products: [
      "Спортивная форма",
      "Тренировочные свитера",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Тренировочные спортивные костюмы",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
      "Сувенирная продукция",
    ],
  },
  {
    name: "schools",
    products: [
      "Спортивная форма",
      "Тренировочные свитера",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Тренировочные спортивные костюмы",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
      "Сувенирная продукция",
    ],
  },
  {
    name: "hockey-prof",
    products: [
      "Игровые свитера на заказ",
      "Тренировочные свитера",
      "Хоккейные чехлы",
      "Гамаши и рейтузы",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Тренировочные спортивные костюмы",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
  {
    name: "football-prof",
    products: [
      "Форма на заказ",
      "Форма для вратаря",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Тренировочные спортивные костюмы",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утепленные куртки и костюмы",
      "Повседневная спортивная одежда",
      "Полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
  {
    name: "basketball-prof",
    products: [
      "Игровая форма на заказ",
      "Разминочные костюмы и майки",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Повседневная спортивная одежда",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
  {
    name: "volleyball",
    products: [
      "Игровая форма на заказ",
      "Разминочные костюмы и майки",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Повседневная спортивная одежда",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
  {
    name: "hockey",
    products: [
      "Игровые свитера на заказ",
      "Тренировочные свитера",
      "Хоккейные чехлы",
      "Гамаши и рейтузы",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Тренировочные спортивные костюмы",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
  {
    name: "football",
    products: [
      "Форма на заказ",
      "Форма для вратаря",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Тренировочные спортивные костюмы",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утепленные куртки и костюмы",
      "Повседневная спортивная одежда",
      "Полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
  {
    name: "basketball",
    products: [
      "Игровая форма на заказ",
      "Разминочные костюмы и майки",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Повседневная спортивная одежда",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
  {
    name: "volleyball",
    products: [
      "Игровая форма на заказ",
      "Разминочные костюмы и майки",
      "Парадные спортивные костюмы",
      "Повседневные спортивные костюмы",
      "Повседневная спортивная одежда",
      "Ветровки и ветрозащитные костюмы",
      "Жилеты",
      "Утеплённые куртки и костюмы",
      "Предсезонная тренировочная одежда",
      "Трикотаж - полный ассортимент шарфов, шапок и рукавиц",
    ],
  },
];
